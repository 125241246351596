import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'
import request from '../util/request'
import { message } from 'antd'
import { MESSAGE_ALERT } from '../constants/app'
import { CONTAINER, IMAGE } from '../constants/component'
import { convertToJSON } from '../util/format'

export const actionUploadImageS3 = createAsyncThunk(
	'images/actionUploadImageS3',
	async (params, { rejectWithValue }) => {
		try {
			const response = await request({
				url: '/files/upload-s3',
				method: 'POST',
				data: params.formData,
			})
			return response
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const isValidComponentTree = (tree) => {
	let isValid = true
	for(const component of tree){
		if(!component.id || !component.type || !component.data) {
			isValid = false
			break
		}
		if(component.type === CONTAINER.type && component.data.childs) {
			return isValidComponentTree(component.data.childs)
		}
	}

	return isValid
}

const updateListImageUrlDefault = (tree, listImageUrl) => {
	for(const component of tree) {
		if(component.type === IMAGE.type && component.data?.listImage) {
			listImageUrl = [...listImageUrl, component.data.listImage].flat()
		}
		if(component.type === CONTAINER.type && component.data?.childs) {
			listImageUrl = updateListImageUrlDefault(component.data.childs, listImageUrl)
		}
	}

	return listImageUrl
}

const initialState = {
	listImageUrl: [],
    isLoading: false
}

export const slice = createSlice({
	name: 'images',
	initialState,
	reducers: {
		actionSetListImageUrl(state, content) {
			const newComponentTree = convertToJSON(content.payload)
			if(newComponentTree && isValidComponentTree(newComponentTree)) {
				let newListImageUrl = []
				newListImageUrl = updateListImageUrlDefault(newComponentTree, newListImageUrl)
				state.listImageUrl = newListImageUrl
			}
		},
		actionRemoveImage(state, content) {
			const newListImageUrl = state.listImageUrl.filter(item => item.key !== content.payload)
			state.listImageUrl = newListImageUrl
		},
		actionAddLinkToListImageUrl(state, content) {
			state.listImageUrl = [...state.listImageUrl, content.payload]
		},
		actionClearListImageUrl(state, content) {
			state.listImageUrl = []
		}
	},

	extraReducers: builder => {
		builder
            .addCase(actionUploadImageS3.pending, (state) => {
                state.isLoading = true;
            })
			.addCase(actionUploadImageS3.fulfilled, (state, action) => {
                const data = get(action, 'payload.data')
				const componentId = get(action, 'meta.arg.componentId')
				const newImageUrl = {...data, componentId, name: data.key}
				message.success(MESSAGE_ALERT.UPLOAD_SUCCESS)
				
				state.listImageUrl = [...state.listImageUrl, newImageUrl]
                state.isLoading = false
			})
			.addCase(actionUploadImageS3.rejected, (state, action) => {
				state.listTemplates = []
                state.isLoading = false
			})
	},
})

export const { actionRemoveImage } = slice.actions
export const { actionSetListImageUrl } = slice.actions
export const { actionAddLinkToListImageUrl } = slice.actions
export const { actionClearListImageUrl } = slice.actions

export const selectListImageUrl = state => state.images.listImageUrl
export const selectLoading = state => state.images.isLoading

export default slice.reducer
