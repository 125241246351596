import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import './index.scss'
import './app/components/PreviewComponent/CustomList.css'
import reportWebVitals from './reportWebVitals'
import Routers from './app/layouts/Routers'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<Routers />
			</BrowserRouter>
		</PersistGate>
	</Provider>
)

reportWebVitals()
