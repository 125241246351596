import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import auth from './authSlice'
import searchCKSlice from './searchCKSlice'
import dataSlice from './dataSlice'
import htmlData from './htmlData'
import templates from './templates'
import images from './images'

const authPersistConfig = {
	key: 'auth',
	storage,
	whitelist: ['userLogin', 'isLogin'],
	version: 1,
}

const reducers = {
	auth: persistReducer(authPersistConfig, auth),
	searchCkSlice: searchCKSlice,
	dataSlice: dataSlice,
	htmlData: htmlData,
	templates: templates,
	images: images,
}

const rootReducer = combineReducers(reducers)

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({ serializableCheck: false }),
})

export const persistor = persistStore(store)
