import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'
import request from '../util/request'

export const actionGetAllTemplates = createAsyncThunk(
	'templates/actionGetAllTemplates',
	async (params, { rejectWithValue }) => {
		try {
			const response = await request({
				url: '/templates',
				method: 'GET',
				params,
			})
			return response
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const actionAddNewTemplate = createAsyncThunk(
	'templates/actionAddNewTemplate',
	async (params, { rejectWithValue }) => {
		try {
			const response = await request({
				url: '/templates',
				method: 'POST',
				data: params,
			})
			return response
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const actionEditTemplateById = createAsyncThunk(
	'templates/actionEditTemplateById',
	async (params, { rejectWithValue }) => {
		try {
			const response = await request({
				url: '/templates',
				method: 'PUT',
				data: params,
			})
			return response
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const actionDeleteTemplateById = createAsyncThunk(
	'templates/actionDeleteTemplateById',
	async (params, { rejectWithValue }) => {
		try {
			const response = await request({
				url: `/templates/${params.id}`,
				method: 'DELETE',
			})
			return response
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

const initialState = {
	listTemplates: [],
	isPostSuccess: false,
	isPutSuccess: false,
	isDeleteSuccess: false,
	template: {},
	prevPage: ""
}

export const slice = createSlice({
	name: 'templates',
	initialState,
	reducers: {
		actionResetStatus(state){
			state.isPostSuccess = false
			state.isPutSuccess = false
			state.isDeleteSuccess = false
		},
		actionSetPrevPage(state, action){
			state.prevPage = action.payload
		}
	},

	extraReducers: builder => {
		builder
			.addCase(actionGetAllTemplates.fulfilled, (state, action) => {
				state.listTemplates = get(action, 'payload.data')
			})
			.addCase(actionGetAllTemplates.rejected, (state, action) => {
				state.listTemplates = []
			})
			.addCase(actionAddNewTemplate.fulfilled, (state, action) => {
				state.isPostSuccess = true
			})
			.addCase(actionAddNewTemplate.rejected, (state, action) => {
				state.isPostSuccess = false
			})
			.addCase(actionEditTemplateById.fulfilled, (state, action) => {
				state.isPutSuccess = true
			})
			.addCase(actionEditTemplateById.rejected, (state, action) => {
				state.isPutSuccess = false
			})
			.addCase(actionDeleteTemplateById.fulfilled, (state, action) => {
				state.isDeleteSuccess = true
			})
			.addCase(actionDeleteTemplateById.rejected, (state, action) => {
				state.isDeleteSuccess = false
			})
	},
})
export const { actionResetStatus } = slice.actions
export const { actionSetPrevPage } = slice.actions

export const selectTemplates = state => state.templates.listTemplates
export const selectPostSuccess = state => state.templates.isPostSuccess
export const selectPutSuccess = state => state.templates.isPutSuccess
export const selectDeleteSuccess = state => state.templates.isDeleteSuccess
export const selectPrevPage = state => state.templates.prevPage

export default slice.reducer
