import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import URL from '../../constants/url';
import { instanceAxios } from '../../util/request';
import { selectIsLogin, selectToken } from '../../store/authSlice';

export default function PrivateRoute({ children }) {
  const isLogin = useSelector(selectIsLogin);
  const token = useSelector(selectToken);
  
  useEffect(() => {
    if (token) {
      instanceAxios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }, [token]);
  
  if (isLogin) {
    return children;
  }
  
  return <Navigate to={URL.Login} replace />;
}
