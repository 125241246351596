import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { CONTAINER, TITLE } from '../constants/component'
import { convertToJSON } from '../util/format'
import { message } from 'antd'
import { MESSAGE_ALERT } from '../constants/app'

const initialState = {
	htmlContent: [],
	isDragging: false,
	componentTree: [],
	nextComponentId: 1,
	componentSavedIds: []
}

const getUpdatedValue = (updateVal, currentVal) => {
	return updateVal ? updateVal : currentVal
}

const updateInComponentTreeById = (componentTree, updateData) => {
	let isUpdated = false
	const clonedTree = cloneDeep(componentTree)
	const updatedTree = clonedTree.map(item => {
		if (item.id === updateData.id) {
			isUpdated = true
			if (item.type === CONTAINER.type) {
				const prevContainer = cloneDeep(item)
				item = {
					...prevContainer,
					data: {
						width: getUpdatedValue(updateData.data.width, prevContainer?.data?.width),
						marginTopBot: getUpdatedValue(updateData.data.marginTopBot, 0),
						marginLeftRight: getUpdatedValue(updateData.data.marginLeftRight, 0),
						paddingVertical: getUpdatedValue(updateData.data.paddingVertical, 0),
						paddingSide: getUpdatedValue(updateData.data.paddingSide, 0),
						backgroundColor: getUpdatedValue(updateData.data.backgroundColor, '#5cb85c'),
						backgroundImage: getUpdatedValue(updateData.data.backgroundImage, ''),
						backGroundCss: getUpdatedValue(updateData.data.backGroundCss, 'background-repeat: no-repeat; background-size: 100% 100%;'),
						childs: getUpdatedValue(updateData.data.childs, prevContainer?.data?.childs),
					},
				}
				return item
			}
			item = updateData
			return item
		}
		if (item.type === CONTAINER.type) {
			if (!item.data) return item
			if (item.data.childs) {
				const resultUpdate = updateInComponentTreeById(item.data.childs, updateData)
				if (resultUpdate.isUpdated) isUpdated = true
				item.data.childs = resultUpdate.updatedTree
				return item
			}
			return item
		}
		return item
	})

	return {
		isUpdated,
		updatedTree: updatedTree
	}
}

const deleteFromComponentTreeById = (componentTree, deleteId) => {
	let removeIndex = -1;
	let isComponentInThisDepth = false
	let removedItem = undefined

	for (let [index, item] of componentTree.entries()) {
		if (item.id === deleteId) {
			removeIndex = index
			isComponentInThisDepth = true
			break
		}
		if (item.type === CONTAINER.type) {
			if (!item.data) continue
			if (item.data.childs && item.data.childs.length) {
				const deleteResult = deleteFromComponentTreeById(item.data.childs, deleteId)
				removeIndex = deleteResult.removeIndex
				removedItem = deleteResult.removedItem
			}
		}
	}

	if (isComponentInThisDepth && removeIndex !== -1) {
		removedItem = componentTree[removeIndex]
		componentTree.splice(removeIndex, 1)
	}

	return {
		removeIndex,
		removedItem
	}
}

const addDuplicateComponentToTree = (item, itemToMove, duplicateIndex) => {
	if (item.type !== CONTAINER.type) return -1
	if (item.data.childs) {
		item.data.childs.splice(duplicateIndex, 0, {...itemToMove})
		return itemToMove
	}

	item.data.childs = [itemToMove]
	return itemToMove
}

const addToTargetComponet = (item, itemToMove) => {
	if (item.type !== CONTAINER.type) return -1
	if (!item.data) {
		item.data = { childs: [itemToMove]}
		return itemToMove
	}
	if (item.data.childs) {
		item.data.childs.push(itemToMove)
		return itemToMove
	}
	item.data.childs = [itemToMove]
	return itemToMove
}

const addToTargetId = (itemToMove, targetId, componentTree, duplicateIndex) => {
	let resultAdd = -1

	for (let item of componentTree) {
		if(item.id === targetId && duplicateIndex){
			return addDuplicateComponentToTree(item, itemToMove, duplicateIndex)
		}

		if (item.id === targetId) {
			return addToTargetComponet(item, itemToMove)
		}

		if (item.type === CONTAINER.type && item.data && item.data.childs && item.data.childs.length !== 0) {
			resultAdd = addToTargetId(itemToMove, targetId, item.data.childs, duplicateIndex)
			if (resultAdd !== -1) return resultAdd
		}
	}

		return resultAdd
}

const swapComponentFromTree = (id, swapId, componentTree) => {
	let resultSwap = -1

	const idIndex = componentTree.findIndex(e => e.id === id)
	if (idIndex !== -1) {
		const swapIndex = componentTree.findIndex(e => e.id === swapId)
		if (swapIndex === -1) {
			resultSwap = -1
		} else {
			const temp = cloneDeep(componentTree[idIndex])
			componentTree[idIndex] = componentTree[swapIndex]
			componentTree[swapIndex] = temp
			return 1
		}
	} 
	for (let item of componentTree) {
		if(item.type === CONTAINER.type) {
			if (item.data && item.data.childs) {
				const resultSwapInChild = swapComponentFromTree(id, swapId, item.data.childs)
				if (resultSwapInChild === 1) return 1
				resultSwap = resultSwapInChild
			}
		}
	}

	return resultSwap
}

const findParentIdByChildId = (targetId, componentTree) => {
	let parentId = -1
	for (let item of componentTree) {
		if (item.type === CONTAINER.type) {
			if (item.data && item.data.childs) {
				const childIndexTarget = item.data.childs.findIndex(e => e.id === targetId)
				if (childIndexTarget !== -1) {
					return item.id
				}
				parentId = findParentIdByChildId(targetId, item.data.childs)
				if (parentId !== -1) {
					return parentId
				}
			}
		}
	}

	return parentId
}

const getDuplicateComponent = (component, state) => {
	let duplicateComponent = {...component, id: state.nextComponentId}
	state.nextComponentId += 1
	state.componentSavedIds = [...state.componentSavedIds, duplicateComponent.id]

	if(duplicateComponent.type === CONTAINER.type && duplicateComponent.data?.childs){
		duplicateComponent.data.childs =  updateChildIdOfDuplicateComponent(duplicateComponent.data.childs, state)
	}

	return duplicateComponent
}

const updateChildIdOfDuplicateComponent = (tree, state) => {
	for(let item of tree){
		item.id = state.nextComponentId
		state.nextComponentId += 1
		state.componentSavedIds = [...state.componentSavedIds, item.id]
		if(item.type === CONTAINER.type && item.data?.childs){
			item.data.childs = updateChildIdOfDuplicateComponent(item.data.childs, state)
		}
	}

	return tree
}

const getComponentSavedIdsDefault = (tree, componentSavedIds, state) => {
	for(const component of tree){
		componentSavedIds.push(component.id)
		state.nextComponentId += 1
		if(component.type === CONTAINER.type && component.data?.childs){
			getComponentSavedIdsDefault(component.data.childs, componentSavedIds, state)
		}
	}
	return componentSavedIds
}

export const findDepthById = (tree, id) => {
	const clonedTree = cloneDeep(tree)
	let currentDepth = 0
	if (!clonedTree) return 1
	for(let item of clonedTree) {
			if (item.id === id) {
					return 1
			}
			if (item.type === CONTAINER.type) {
					if (item.data && item.data.childs) {
							const prevDepth = findDepthById(item.data.childs, id)
							if (prevDepth !== 0) currentDepth = prevDepth + 1
					}
			}
	}

	return currentDepth
}

const isValidComponentTree = (tree) => {
	let isValid = true
	for(const component of tree){
		if(!component.id || !component.type || !component.data) {
			isValid = false
			break
		}
		if(component.type === CONTAINER.type && component.data.childs) {
			return isValidComponentTree(component.data.childs)
		}
	}

	return isValid
}

export const slice = createSlice({
	name: 'htmlData',
	initialState,
	reducers: {
		actionChangeHtmlContent(state, content) {
			state.htmlContent = content.payload
		},
		actionChangeIsDragging(state, content) {
			state.isDragging = content.payload
		},
		actionAddToComponentTree(state, content) {
			const nextComponentTree = cloneDeep(state.componentTree)
			if (!nextComponentTree.length) {
				nextComponentTree.push(content.payload)
				state.componentTree = nextComponentTree
				return
			}

			const updateItem = content.payload
			const updateResult = updateInComponentTreeById(nextComponentTree, updateItem)
			if (updateResult.isUpdated) {
				state.componentTree = updateResult.updatedTree
				return
			}

			if(content.payload.type === TITLE.type) {
				const titleComponent = nextComponentTree.find(item => item.type === TITLE.type)
				if(titleComponent) {
					message.error(MESSAGE_ALERT.ADD_SECOND_TITLE_COMPONENT)
					return
				}

				nextComponentTree.unshift(content.payload)
				state.componentTree = nextComponentTree
				return
			}

			nextComponentTree.push(content.payload)
			state.componentTree = nextComponentTree
		},
		actionDeleteFromComponentTree(state, content) {
			const { deleteId } = content.payload
			const componentTree = cloneDeep(state.componentTree)
			deleteFromComponentTreeById(componentTree, deleteId)
			state.componentTree = componentTree
		},
		actionMoveToTarget(state, content) {
			const { itemId, targetId } = content.payload
			const componentTree = cloneDeep(state.componentTree)
			const itemToMove = deleteFromComponentTreeById(componentTree, itemId).removedItem

			const resultAdd = addToTargetId(itemToMove, targetId, componentTree)
			if (resultAdd === -1) return
			state.componentTree = componentTree
			state.componentSavedIds = [...state.componentSavedIds, resultAdd.id]
		},
		actionMoveToFirstDepth(state, content) {
			const { itemId } = content.payload
			const nextComponentTree = cloneDeep(state.componentTree)
			const itemToMove = deleteFromComponentTreeById(nextComponentTree, itemId).removedItem

			nextComponentTree.push(itemToMove)
			state.componentTree = nextComponentTree
		},
		actionSwapComponentFromComponentTree(state, content) {
			const componentTree = cloneDeep(state.componentTree)
			const { id, swapId } = content?.payload
			swapComponentFromTree(id, swapId, componentTree)
			state.componentTree = componentTree
		},
		actionMoveToSameParent(state, content) {
			const componentTree = cloneDeep(state.componentTree)
			const { id, targetId } = content?.payload

			const parentId = findParentIdByChildId(targetId, componentTree)
			if (parentId === -1) return

			const itemToMove = deleteFromComponentTreeById(componentTree, id).removedItem
			const resultAdd = addToTargetId(itemToMove, parentId, componentTree)
			if (resultAdd === -1) return
			state.componentTree = componentTree
			state.componentSavedIds = [...state.componentSavedIds, resultAdd.id]
		},
		actionClearComponentTree(state){
			state.componentTree = []
			state.nextComponentId = 1
			state.componentSavedIds = []
		},
		actionSetComponentTree(state, content){
			const newComponentTree = convertToJSON(content.payload)
			if(newComponentTree && isValidComponentTree(newComponentTree)) {
				let componentSavedIds = cloneDeep(state.componentSavedIds)
				componentSavedIds = getComponentSavedIdsDefault(newComponentTree, componentSavedIds, state)
	
				state.componentTree = newComponentTree
				state.componentSavedIds = componentSavedIds
			}
		},
		actionAddOneToNextComponentId(state, content){
			state.nextComponentId = state.nextComponentId + 1
		},
		actionSetListChildIdOfContainerDuplicate(state, content){
			state.componentSavedIds = [...state.componentSavedIds, content.payload]
		},
		actionAddDuplicateComponentToTree(state, content){
			let componentTree = cloneDeep(state.componentTree)
			const { componentSelectedIndex, componentSelected } = content.payload
			const duplicateIndex = componentSelectedIndex + 1

			const duplicateComponent = getDuplicateComponent(componentSelected, state)
			const parentId = findParentIdByChildId(componentSelected.id, componentTree)
			if(parentId === -1){
				componentTree.splice(duplicateIndex, 0, {...duplicateComponent})
				state.componentTree = componentTree
				return;
			}

			const resultAdd = addToTargetId(duplicateComponent, parentId, componentTree, duplicateIndex)
			if (resultAdd === -1) return
			state.componentTree = componentTree
		}
	},
})

export const { actionChangeHtmlContent } = slice.actions

export const { actionChangeIsDragging } = slice.actions

export const { actionAddToComponentTree } = slice.actions

export const { actionDeleteFromComponentTree } = slice.actions

export const { actionSwapComponentFromComponentTree } = slice.actions

export const { actionClearComponentTree } = slice.actions

export const { actionSetComponentTree } = slice.actions

export const { actionAddOneToNextComponentId } = slice.actions

export const { actionMoveToTarget } = slice.actions

export const { actionMoveToFirstDepth } = slice.actions

export const { actionMoveToSameParent } = slice.actions

export const { actionSetListChildIdOfContainerDuplicate } = slice.actions

export const { actionAddDuplicateComponentToTree } = slice.actions

export const selectNextComponentId = state => state.htmlData.nextComponentId

export const selectContentHtml = state => state.htmlData.htmlContent

export const selectIsDragging = state => state.htmlData.isDragging

export const selectComponentTree = state => state.htmlData.componentTree

export const selectComponentSavedIds = state => state.htmlData.componentSavedIds

export default slice.reducer
