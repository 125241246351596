import { Layout, Menu } from 'antd';
import logo from '../images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import URL from '../../constants/url';
import { useDispatch, useSelector } from 'react-redux';
import { actionClearComponentTree, selectComponentTree } from '../../store/htmlData';
import { actionSetPrevPage } from '../../store/templates';
import { actionClearListImageUrl } from '../../store/images';
const { Header, Content, Footer } = Layout;

export default function DefaultLayout({children}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const componentTree = useSelector(selectComponentTree)

  const handleMenuRouter = (url) => {
    if(location.pathname === URL.Home && componentTree.length){
      const userConfirmed = window.confirm('Do you want exit without save template?');
			if (!userConfirmed) {
				return
			}
    }

    dispatch(actionClearComponentTree())
    dispatch(actionClearListImageUrl())
    dispatch(actionSetPrevPage(window.location.pathname))
    navigate(url)
  }

  return (
    <Layout className="layout">
      
      <Header>
        <div className="logo">
          <img src={logo} alt='' onClick={() => handleMenuRouter(URL.Home)}/>
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={[
            {
              key: 'Home',
              label: 'Home',
              onClick: () => handleMenuRouter(URL.Home)
            },
            {
              key: 'Templates',
              label: 'Templates',
              onClick: () => handleMenuRouter(URL.Templates)
            },
          ]}
        />
      </Header>
      <Content
        style={{
          padding: '0 50px',
        }}
      >
        <div
          className="site-layout-content"
          style={{
            background: '#fff',
            display: window.location.pathname === URL.Templates ? 'block' : 'flex',
          }}
        >
          {children}
        </div>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
        }}
      >
        Ant Design ©2023 Created by Ant UED
      </Footer>
    </Layout>
  );
}
