import { selectComponentTree, actionMoveToFirstDepth, findDepthById } from '../../store/htmlData'
import { useDispatch, useSelector } from 'react-redux'
import { useDrop } from 'react-dnd'
import { Layout } from 'antd';
import { cloneDeep } from "lodash"

const { Content } = Layout;

const MainLayout = ({ children }) => {
  const dispatch = useDispatch()
  const componentTree = useSelector(selectComponentTree)
  const [, dropRef] = useDrop(() => ({
		accept: "pic",
		drop: (item, monitor) => {
				const didDrop = monitor.didDrop()
				if (didDrop) {
						return
				}
				const depth = findDepthById(cloneDeep(componentTree), item.id)
				if (depth > 1) {
					dispatch(actionMoveToFirstDepth({itemId: item.id}))
				}
		},
		collect: (monitor) => ({
				isOver: monitor.isOver(),
				isOverCurrent: monitor.isOver({ shallow: true }),
		}),
	}), [componentTree])
  return (
    <Layout ref={dropRef}>
      <Content className="site-layout">
        <div className="site-layout-background">
          {children}
        </div>
      </Content>
    </Layout>
)};

export default MainLayout;
