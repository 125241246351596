import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

const initialState = {
    dataPreview: '',
}

export const slice = createSlice({
	name: 'dataSlice',
	initialState,
	reducers: {
        actionSetDataPreview(state, action) {
            state.dataPreview = get(action, 'payload.data');
        }
    },

})

export const { actionSetDataPreview } = slice.actions
export const selectDataPreview = state => state.dataSlice.dataPreview;

export default slice.reducer
