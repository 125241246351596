import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'
import request from '../util/request'

const initialState = {
	isLogin: false,
	loadingLogin: false,
	userLogin: {
		status: 0,
		token: '',
		user: {
			isLog: false,
			isCreate: false,
			id: 0,
			displayName: '',
			createdAt: '',
			updatedAt: '',
			username: '',
		},
		expired: '',
	},
	connectionId: 'tidesquare',
}

export const actionLoginKeyCloak = createAsyncThunk(
	'auth/actionLoginKeyCloak',
	async (code, { rejectWithValue }) => {
		try {
			return await request({
				url: '/oauth2/keycloak/login',
				method: 'POST',
				data: {
					code,
				},
			})
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		actionLogout(state) {
			state.isLogin = false
			state.userLogin = {
				status: 0,
				token: '',
				user: {
					isLog: false,
					isCreate: false,
					id: 0,
					displayName: '',
					createdAt: '',
					updatedAt: '',
					username: '',
				},
				expired: '',
			}
		},
	},
	extraReducers: builder => {
		builder
			.addCase(actionLoginKeyCloak.pending, state => {
				state.loadingLogin = true
			})
			.addCase(actionLoginKeyCloak.fulfilled, (state, action) => {
				const data = get(action, 'payload.data')
				state.isLogin = true
				state.userLogin.token = data.access_token
				state.loadingLogin = false
			})
			.addCase(actionLoginKeyCloak.rejected, state => {
				state.loadingLogin = false
			})
	},
})

export const { actionLogout } = slice.actions

export const selectUser = state => state.auth.userLogin

export const selectLoadingLogin = state => state.auth.loadingLogin

export const selectIsLogin = state => state.auth.isLogin

export const selectToken = state => state.auth.userLogin.token

export const selectConnectionId = state => state.auth.connectionId

export default slice.reducer
