export const formatDate = (createdAt, updatedAt) => {
  let dateTimeString = updatedAt || createdAt
  const dateTime = new Date(dateTimeString)
  const year = dateTime.getFullYear()
  const month = String(dateTime.getMonth() + 1).padStart(2, '0')
  const day = String(dateTime.getDate()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export const setProperty = (isComponentSavedDefault, dataParent, dataChild) => {
  if(isComponentSavedDefault && dataParent){
    return dataParent
  }
  return dataChild
}

export const convertToJSON = (string) => {
	try{
		return JSON.parse(string)
	} catch(e) {
		return null
	}
}