export const THEME = {
  type: 'Theme',
  label: 'Theme',
  key: '1'
}

export const IMAGE = {
  type: 'Image',
  label: 'Image',
  key: '2'
}

export const TEXT = {
  type: 'Text',
  label: 'Text',
  key: '3'
}

export const BUTTON = {
  type: 'Button',
  label: 'Button',
  key: '4'
}

export const CARD = {
  type: 'Card',
  label: 'Card',
  key: '5'
}

export const ACCORDION = {
  type: 'Accordion',
  label: 'Accordion',
  key: '6'
}

export const CAROUSEL = {
  type: 'Carousel',
  label: 'Carousel',
  key: '7'
}

export const QUOTE = {
  type: 'Quote',
  label: 'Quote',
  key: '8'
}

export const TAB = {
  type: 'Tab',
  label: 'Tab',
  key: '9'
}

export const TEASER = {
  type: 'Teaser',
  label: 'Teaser',
  key: '10',
	variantStyle: 'border-radius: 2%; border: 1px solid black; overflow: hidden;',
	verticalStyle: 'display: flex; flex-direction: row;',
	responsiveStyle: 'display: flex; flex-direction: column;	justify-content: center; margin-left: 10px;',
	horizontalStyle: 'margin-left: 10px;',
}

export const CONTAINER = {
  type: 'Container',
  label: 'Container',
  key: '11'
}

export const TITLE = {
  type: 'Title',
  label: 'Title',
  key: '12'
}

export const fontsizes = [
	{
		value: '8',
		label: '8'
	},
	{
		value: '9',
		label: '9'
	},
	{
		value: '10',
		label: '10'
	},
	{
		value: '11',
		label: '11'
	},
	{
		value: '12',
		label: '12'
	},
	{
		value: '13',
		label: '13'
	},
	{
		value: '14',
		label: '14'
	},
	{
		value: '18',
		label: '18'
	},
	{
		value: '24',
		label: '24'
	},
	{
		value: '30',
		label: '30'
	},
	{
		value: '36',
		label: '36'
	},
	{
		value: '48',
		label: '48'
	},
	{
		value: '60',
		label: '60'
	},
	{
		value: '72',
		label: '72'
	},
	{
		value: '96',
		label: '96'
	},
]
