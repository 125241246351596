export const ERROR_MESSAGE = 'Error'
export const PAGE_SIZE = 15

export const SEARCH_STD = [
	{
		label: 'Id',
		value: 'ID',
	},
	{
		label: 'Title',
		value: 'TITLE',
	},
]

export const THEME_TAB = [
	{
		value: 'TAB',
		label: '탭',
	},
	{
		value: 'THEME',
		label: '테마',
	},
]

export const TEMPLATE_STATUS = [
	{
		value: 1,
		label: '작업 중',
	},
	{
		value: 2,
		label: '완료',
	},
]

export const STATUS_RESPONSE = {
	SUCCESS: 200,
	CREATED: 201
}

export const MESSAGE_ALERT = {
	SAVE_BEFORE_DUPLICATE: 'If you want to duplicate this component, please save it first',
	DUPLICATE_THEME: `You can't duplicate a theme`,
	DUPLICATE_TITLE: `You can't duplicate Tittle Component`,
	ADD_ACCORDION_INFORMATION: 'Please add title and content of accordion',
	ADD_LINK_IMAGE: 'Please input the link of image',
	UPLOAD_OVER_FIVE_IMAGE: 'You can only upload 5 images',
	DELETE_SUCCESS: 'Deleted successfully',
	UPLOAD_SUCCESS: 'File uploaded successfully',
	ADD_SIMILAR_THEM: `You can't add 2 similar themes`,
	ADD_SECOND_TITLE_COMPONENT: `You can only add 1 Tittle Component`
}

export const MAX_IMAGE_CARD_UPLOAD = 5
export const TIME_INTERVAL_UPDATE_THEME_PRODUCTS = 10000