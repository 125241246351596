import DefaultLayout from './DefaultLayout';
import PrivateRoute from './PrivateRouter';
import {lazy, Suspense} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import URL from '../../constants/url';
import NotFound from '../pages/Results/NotFound';
import MainLayout from "./MainLayout";
const DEFAULT_LAYOUT = 'default';
const NONE_LAYOUT = 'none';

const Login = lazy(() => import('../pages/Login/index'));
const Templates = lazy(() => import('../pages/Templates'));
const Home = lazy(() => import('../pages/Home'));
const RedirectedLogin = lazy(() => import('../pages/RedirectedLogin'));

const itemRouter = [
  {
    key: URL.Home,
    components: <Home />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
  {
    key: URL.Templates,
    components: <Templates />,
    layout: DEFAULT_LAYOUT,
    private: false,
  },
  {
    key: URL.Login,
    components: <Login />,
    layout: NONE_LAYOUT,
    private: false,
  },
  {
    key: URL.RedirectedLogin,
    components: <RedirectedLogin />,
    layout: NONE_LAYOUT,
    private: false,
  },
  {
    key: '/',
    components: <Navigate to={URL.Login} />,
    layout: NONE_LAYOUT,
    private: false,
  },
  {
    key: '*',
    components: <NotFound />,
    layout: NONE_LAYOUT,
    private: false,
  },
];

export default function Routers() {
  return (
    <DndProvider backend={HTML5Backend}>
      <MainLayout>
        <Routes>
          {itemRouter.map((item) => {
            let element = item.components;
            element = <Suspense fallback={null}>{element}</Suspense>;
            if (item.layout === DEFAULT_LAYOUT) {
              element = <DefaultLayout>{element}</DefaultLayout>;
            }
            if (item.private) {
              element = <PrivateRoute>{element}</PrivateRoute>;
            }
            return <Route key={item.key} path={item.key} element={element} />;
          })}
        </Routes>
      </MainLayout>
    </DndProvider>
  );
}
