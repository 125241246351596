import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'
import requestTheme from '../util/requestTheme'

const initialState = {
	loading: false,
	data: null,
}

export const actionSearch = createAsyncThunk(
	'theme/getThemeTabInfoList',
	async (params, { rejectWithValue }) => {
		try {
			return await requestTheme({
				url: 'theme/getThemeTabInfoList',
				method: 'POST',
				data: params,
			})
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const slice = createSlice({
	name: 'searchCkSlice',
	initialState,
	reducers: {},
	actionSearchCk(state) {},
	extraReducers: builder => {
		builder
			.addCase(actionSearch.pending, state => {
				state.loading = true
			})
			.addCase(actionSearch.fulfilled, (state, action) => {
				const data = get(action, 'payload.data')
				state.data = data
				state.loading = false
			})
			.addCase(actionSearch.rejected, state => {
				state.loading = false
			})
	},
})

export const { actionLogout } = slice.actions
export const selectLoadingSearchCk = state => state.searchCkSlice.loading
export const selectDataSearchCk = state => state.searchCkSlice.data

export default slice.reducer
